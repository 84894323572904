import { Injectable } from '@angular/core';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { Message } from '@pepconnect/models/message-center/messages.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {

  constructor(private apiSvc: ApiService) { }

  getSiteMessages(localeId: number): Observable<Message[]> {
    return this.apiSvc.get<Message[]>(`messages/site?localeid=${localeId}`);
  }

  getUnreadCount(localeId: number): Observable<number> {
    return this.apiSvc.get<number>(`messages/count?localeid=${localeId}`);
  }

  fetchMessages(localeId: number) {
    return this.apiSvc.get<Message[]>(`messages?localeid=${localeId}`);
  }

  markRead(apiURL: string, localeId: number) {
    return this.apiSvc.put<UnserializedResponseMessage>(apiURL, localeId);
  };
}
